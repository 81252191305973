import { Identify, identify, track } from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import PickupStoreWithContext from '@tb-core/components/composites/pickup-store-with-context';
import StandardToolbar from '@tb-core/components/composites/standard-toolbar';
import CartIconProviderWrapper from '@tb-core/components/context/cart-icon';
import MenuProvider from '@tb-core/components/context/menu';
import ClientSideCartProvider from '@tb-core/components/context/products/client-side-cart';
import StoreProvider from '@tb-core/components/context/store';
import withNextConfig from '@tb-core/components/hoc/with-next-config';
import { amplitudeInitialize } from '@tb-core/helpers/analytics/amplitude';
import { optimizelyClient } from '@tb-core/helpers/analytics/optimizely';
import { interpolate } from '@tb-core/helpers/interpolate';
import { brazeEnabled } from '@tb-core/helpers/next-env';
import { shaGenerator } from '@tb-core/helpers/sha-generator';
import { kebabToTitleCase } from '@tb-core/helpers/string';
import { useOptimizelyFlag } from '@tb-core/hooks/analytics/use-optimizely-flag';
import usePageContext from '@tb-core/hooks/use-page-context';
import useServiceWorker from '@tb-core/hooks/use-service-worker';
import useUserContext from '@tb-core/hooks/use-user-context';
import { useWebview } from '@tb-public/hooks/use-webview';

import styles from './styles.module.scss';

const loadBraze = async (email: string) => {
    const { initializeBraze } = await import('@tb-core/helpers/braze');
    initializeBraze(email);
};

const GlobalHeader = () => {
    const { isWebView } = useWebview();

    const { asPath, query } = useRouter();
    const pagePath = asPath;
    const user = useUserContext();
    const { isLoggedIn, userUid } = user;
    if (
        isLoggedIn &&
        typeof window !== 'undefined' &&
        brazeEnabled === 'true'
    ) {
        loadBraze(userUid || '');
    }

    const {
        content: { pageData }
    } = usePageContext();
    const pageControls = pageData?.layout.pageControls;
    // optimizely flag enabling for event tracking
    const tbEventsEnabled = useOptimizelyFlag('tb_events');
    let pageTitle = pageData.seoTitle;

    if (query?.product && pageData.seoTitle.includes('{productName}')) {
        pageTitle = interpolate(pageData.seoTitle, {
            productName: kebabToTitleCase(String(query.product))
        });
    }

    useEffect(() => {
        const identifyObj = new Identify();

        if (query.guid) {
            identifyObj.set('guid', query.guid);
            identify(identifyObj);
        }

        if (isLoggedIn !== undefined) {
            const shaUser = userUid ? shaGenerator(userUid) : undefined;
            amplitudeInitialize(shaUser);

            if (isLoggedIn && user.membershipLevelName) {
                identifyObj.set('loyalty_tier', user.membershipLevelName);
                identify(identifyObj);
            }

            if (pageData) {
                const pageProps = {
                    page_path: pagePath,
                    page_title: pageTitle,
                    webview: isWebView
                };

                const trackOptimizelyPage = (pagePath: string) => {
                    switch (pagePath) {
                        case '/':
                            optimizelyClient.track('viewed_homepage');
                            break;
                        case '/food':
                            optimizelyClient.track('viewed_menu_page');
                            break;
                        case '/order/cart':
                            optimizelyClient.track('viewed_cart_page');
                            break;
                        case '/order/confirmation':
                            optimizelyClient.track(
                                'viewed_order_confirmation_page'
                            );
                            break;
                        default:
                            break;
                    }
                };

                track('Viewed Page/Screen', pageProps);
                if (tbEventsEnabled) {
                    trackOptimizelyPage(pagePath);
                }
            }
        }
    }, [isLoggedIn, userUid]);

    // The service worker function's purpose is solely to load in braze.
    // If braze is disabled, there is no reason to load the service worker to enable
    // pop up notifications.
    if (brazeEnabled === 'true') {
        useServiceWorker();
    }

    // if isWebView return empty component
    if (isWebView) {
        return <></>;
    }

    return (
        <header className={styles.header}>
            <StoreProvider>
                <MenuProvider>
                    <ClientSideCartProvider>
                        <CartIconProviderWrapper
                            condition={pageControls?.indexOf('Cart') >= 0}
                        >
                            <>
                                <StandardToolbar />
                                {pageControls?.indexOf('Selected Store') >=
                                    0 && (
                                    <PickupStoreWithContext
                                        collapsed={true}
                                        isNav={true}
                                    />
                                )}
                            </>
                        </CartIconProviderWrapper>
                    </ClientSideCartProvider>
                </MenuProvider>
            </StoreProvider>
        </header>
    );
};
export default withNextConfig(GlobalHeader);
