import Head from 'next/head';
import React, { ReactNode, useMemo } from 'react';

import { MetaTagProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';
import { dataDogInitialize } from '@tb-core/helpers/analytics/data-dog';
import { metaAttributeAdapter } from '@tb-core/helpers/meta-tags';
import { assetPath, dataDogEnv, webOrigin } from '@tb-core/helpers/next-env';
import usePageContext from '@tb-core/hooks/use-page-context';

interface DocumentHeadProps {
    canonicalUrlOverride?: string;
    children?: ReactNode;
    descriptionOverride?: string;
    titleOverride?: string;
}

const DocumentHeadRegion = ({
    canonicalUrlOverride,
    children,
    descriptionOverride,
    titleOverride
}: DocumentHeadProps) => {
    const {
        content: { pageData }
    } = usePageContext();
    const {
        metaTagsCollection,
        seoCanonicalUrl,
        seoDescription,
        seoTitle,
        topicMeta
    } = pageData;

    // Use NPM for Data Dog for init and logs
    if (dataDogEnv) {
        dataDogInitialize(dataDogEnv);
    }

    return useMemo(() => {
        return (
            <Head>
                {/* SEO specific elements */}
                <title>{titleOverride || seoTitle}</title>
                <meta
                    content={descriptionOverride || seoDescription}
                    key="seoDescription"
                    name="description"
                />
                <link
                    href={webOrigin + (canonicalUrlOverride || seoCanonicalUrl)}
                    key="seoCanonical"
                    rel="canonical"
                />
                {/* End of SEO specific elements */}
                <meta name="format-detection" content="telephone=no" />
                {metaTagsCollection &&
                    metaTagsCollection?.items.map(
                        (metaTag: MetaTagProps, i: number) => (
                            <meta key={i} {...metaAttributeAdapter(metaTag)} />
                        )
                    )}
                <link rel="preconnect" href="https://cdn.optimizely.com" />
                {assetPath && assetPath.startsWith('http') && (
                    <>
                        <link rel="preconnect" href={assetPath} />
                    </>
                )}
                {children}
            </Head>
        );
    }, [
        assetPath,
        canonicalUrlOverride,
        descriptionOverride,
        seoCanonicalUrl,
        seoDescription,
        seoTitle,
        titleOverride,
        topicMeta
    ]);
};

export default DocumentHeadRegion;
