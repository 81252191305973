import { track } from '@amplitude/analytics-browser';

import NavigationLink from '@tb-core/components/composites/navigation-link';
import ItemList, { ListItem } from '@tb-core/components/simple/item-list';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { LayoutNavigationProps, LinkProps } from '@tb-core/types';

import styles from './styles.module.scss';

const MOBILE_APP_LABEL = 'Mobile App';

const FooterNavigation = ({ links = [] }: LayoutNavigationProps) => {
    const onClick = (props: LinkProps) => {
        updateDataLayer({
            event: 'menu_navigation',
            link_text: props.label,
            menu_type: 'footer'
        });

        if (props.label === MOBILE_APP_LABEL) {
            const mobileAppClickPayload = {
                cta: props.label,
                header_text: 'Stay Connected',
                page_path: window.location.pathname
            };
            track('Clicked Footer Navigation', mobileAppClickPayload);
        }
    };

    const listItem: ListItem = (props: LinkProps, i) => (
        <NavigationLink
            {...props}
            gaDataLayerConfig={{
                'Analytics-Action': 'Footer',
                'Analytics-Value': props.label
            }}
            key={i}
            onClick={() => onClick(props)}
        />
    );

    return (
        <nav className={`${styles['footer-navigation']} ${styles.navi}`}>
            <ItemList item={listItem} itemProps={links} />
        </nav>
    );
};

export default FooterNavigation;
